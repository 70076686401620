import { ModeProvider } from "./themes/modeContext";
import { ThemeContext } from "./themes/themeContext";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./styles/index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <ModeProvider>
    <ThemeContext>
      <App />
    </ThemeContext>
  </ModeProvider>
  // </React.StrictMode>
);
