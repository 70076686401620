import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

// Components
import Card from "../common/Card";
import CardHeader from "../common/CardHeader";

// Data
import { socialInfo } from "../../data/data";

const SocialCard = () => {
  return (
    <Card>
      <CardHeader title="Social" />
      <List disablePadding sx={{}}>
        {socialInfo.map((item) => (
          <ListItem disablePadding key={item.text}>
            <ListItemButton href={item.link}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Card>
  );
};

export default SocialCard;
