// icons
import EmailIcon from "@mui/icons-material/Email";
import WorkIcon from "@mui/icons-material/Work";
import SchoolIcon from "@mui/icons-material/School";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import CollectionsIcon from "@mui/icons-material/Collections";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import EngineeringIcon from "@mui/icons-material/Engineering";

export const contactInfo = [
  {
    icon: <EmailIcon />,
    text: "maxwanless@gmail.com",
    link: "mailto:maxwanless@gmail.com",
  },
  {
    icon: <LinkedInIcon />,
    text: "linkedin.com/in/maxwanless",
    link: "http://www.linkedin.com/in/maxwanless",
  },
  {
    icon: <GitHubIcon />,
    text: "github.com/MaxWanless",
    link: "http://www.github.com/MaxWanless",
  },
];

export const pages = [
  { label: "About", link: "", icon: <AccountBoxIcon /> },
  { label: "Projects", link: "projects", icon: <CollectionsIcon /> },
  { label: "Experience", link: "experience", icon: <EngineeringIcon /> },
  { label: "Contact", link: "contact", icon: <ContactSupportIcon /> },
];

export const aboutInfo = [
  {
    icon: <EmailIcon />,
    text: "maxwanless@gmail.com",
  },
  {
    icon: <WorkIcon />,
    text: "Freelance Web developer",
  },
  {
    icon: <SchoolIcon />,
    text: "Studied At BrainStation",
  },
];

export const socialInfo = [
  {
    icon: <LinkedInIcon />,
    text: "linkedin.com/in/maxwanless",
    link: "http://www.linkedin.com/in/maxwanless",
  },
  {
    icon: <GitHubIcon />,
    text: "github.com/MaxWanless",
    link: "http://www.github.com/MaxWanless",
  },
];

export const skillsInfo = [
  {
    title: "Frontend",
    skills: [
      "JavaScript",
      "TypeScript",
      "HTML",
      "CSS",
      "Scss",
      "Material UI",
      "React.js",
      "React-Native",
      "Redux",
    ],
  },
  {
    title: "Backend",
    skills: [
      "Rest API",
      "Node.js",
      "Express",
      "MySQL",
      "MongoDB",
      "Firebase",
      "OAuth",
      "JWT",
    ],
  },
  {
    title: "Tools",
    skills: [
      "Git",
      "GitHub",
      "NPM",
      "VS Code",
      "Heroku",
      "Netlify",
      "Render",
      "Jest",
    ],
  },
  {
    title: "Other",
    skills: [
      "Agile",
      "TDD",
      "Project Management",
      "Collaboration",
      "Communication",
    ],
  },
];

export const resumeInfo = [
  {
    year: "2021",
    position: "Intermediate Controls Engineer",
    company: "RidgeTech",
    description:
      "At RidgeTech, I served as an intermediate controls engineer where I was responsible for designing and developing control software and hardware packages. This included creating PLC, HMI, SCADA and servo programs, as well as electrical, pneumatic and hydraulic schematics. Additionally, I led commissioning on-site, including the initial hardware setup and troubleshooting, as well as ensuring the smooth and efficient implementation of new controls systems. This role allowed me to apply my technical expertise and demonstrate my ability to lead complex projects and deliver  high-quality and efficient control systems to their clients.",
  },
  {
    year: "2018",
    position: "Intermediate Controls Engineer",
    company: "Cooper Standard Automotive",
    description:
      "At Cooper Standard Automotive, I worked as an Intermediate Controls Engineer where I made significant contributions to the company's controls systems. My responsibilities included designing and developing new control systems to increase product quality and process efficiency, as well as directing and coordinating Electrical Controls projects. Additionally, I was in charge of maintaining and troubleshooting existing control solutions within a production environment. This role provided me with the opportunity to showcase my technical expertise and project management skills, and to make a positive impact on the company's operations.",
  },
  {
    year: "2017",
    position: "Junior Controls Engineer",
    company: "Eclipse Automation",
    description:
      "During my time at Eclipse Automation as a Junior Controls Engineer, I was responsible for designing and developing controls software packages for a wide range of automated machinery. I was also an integral part of the commissioning team, where I supported the initial set up of PLCs, Vision Systems, Robots and HMIs for brand new automated equipment. This role allowed me to gain hands-on experience in the field of controls engineering and to demonstrate my technical skills in a real-world setting. I was committed to delivering high-quality and efficient controls solutions, and I was proud to be a part of a team that was at the forefront of automation technology",
  },
  {
    year: "2016",
    position: "Co-Op Controls Engineer",
    company: "Tesla",
    description:
      "During my co-op at Tesla as a Controls Engineer, I made significant contributions to the controls team by developing standard software packages that included Allen Bradley Add-on Instructions, Ignition HMI templates, data types, sample PLC code, and documentation. These packages were distributed to vendors and helped streamline their work. I also commissioned a development automation cell, which consisted of a Fanuc robot, Allen Bradley PLC, Cognex Camera, and an Ignition Scada system. This project allowed me to gain valuable skills in developing complex controls systems and to demonstrate my ability to work independently and effectively as part of a team.",
  },
];

export const projectList = [
  {
    title: "Kanban",
    subTitle: "Concept Project",
    image: require("../assets/screenshots/Kanban_Screenshot.webp"),
    altImage: "Kanban Screenshot",
    tech: ["React", "Redux", "MongoDB", "Express"],
    role: "Full-Stack Developer",
    teammate: "N/A",
    timeline: "2022",
    description: "A Kanban web app concept for tracking project progress.",
    context:
      "Taking inspiration from Notion, I decided to develop my own kanban application and use the opportunity to learn MongoDB and Redux. This project helped me enhance my full-stack development abilities and gain practical knowledge of cutting-edge technologies widely used in the industry. The kanban application I developed is flexible and efficient, and I believe it will provide value to users in their workflows. This project was a challenging and educational experience, and I look forward to continuing to develop my skills as well as maintaining and updating this project in the future.",
    github: "https://github.com/MaxWanless/max-wanless-kanban-app",
    demo: "https://max-wanless-kanban.netlify.app",
  },
  {
    title: "Evogym",
    subTitle: "Single Page Website",
    image: require("../assets/screenshots/Evogym_Screenshot.webp"),
    altImage: "Evogym Screenshot",
    tech: ["React", "Typescript", "Tailwind", "Framer Motion"],
    role: "Frontend Developer",
    teammate: "N/A",
    timeline: "2022",
    description:
      "A single page react application that uses Tailwind, Typescript and framer motion.",
    context:
      "I used this project to expand my skills and learn Typescript, Tailwindcss, and Framer Motion. This project allowed me to gain hands-on experience with modern technologies that are widely used in web development. The project was a valuable learning experience, and I am excited to continue using what I have learned to build more projects and advance my skills as a web developer.",
    github: "https://github.com/MaxWanless/max-wanless-evogym",
    demo: "https://max-wanless-evogym.netlify.app",
  },
  {
    title: "TrailBLZR",
    subTitle: "Brainstation Capstone",
    image: require("../assets/screenshots/TrailBLZR_Screenshot.webp"),
    altImage: "TrailBLZR Screenshot",
    tech: ["React", "Express", "Node", "MySQL"],
    role: "Full-Stack Developer",
    teammate: "N/A",
    timeline: "2022",
    description:
      "An intuitive way to find hiking trails and information about provincial parks in Ontario.",
    context:
      "This project was designed to showcase the skills and knowledge I acquired during my web development course at Brainstation. As my capstone project, I leveraged my experience with front-end and back-end technologies, including React, Express, MySQL, and Material UI, to create a comprehensive and user-friendly hiking and parks information website. The application provides an intuitive way for outdoor enthusiasts to find hiking trails and gather information about provincial parks in Ontario. It integrates mapping features via the Google Maps API, as well as trail and park information supplied by a robust back-end powered by Express and a MySQL database. It was a challenging and educational experience to get everything implemented and deployed within the 2-week deadline, and I look forward to improving its functionality in the future.",
    github: "https://github.com/MaxWanless/max-wanless-trailblzr",
    demo: null,
  },

  // {
  //   title: "NotFlix",
  //   subTitle: "24hr collabrative hackathon",
  //   image: require("../assets/screenshots/NotFlix_Screenshot.webp"),
  //   altImage: "NotFlix Screenshot",
  //   tech: ["React.js", "Sass", "API", "Colaboration"],
  //   role: "Full-Stack Developer",
  //   teammate: "Shoors",
  //   timeline: "2022",
  //   description:
  //     "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam, praesentium ex, consequatur nemo, voluptatem possimus repudiandae mollitia illo doloribus officia sint minus dolores sequi optio dolorem at cum labore veniam.",
  //   context:
  //     "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam, praesentium ex, consequatur nemo, voluptatem possimus repudiandae mollitia illo doloribus officia sint minus dolores sequi optio dolorem at cum labore veniam.",
  //   github: null,
  //   demo: null,
  // },
];
