import { motion } from "framer-motion";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";

// Components
import Card from "../common/Card";
import CardHeader from "../common/CardHeader";

// Data
import { skillsInfo } from "../../data/data";

const SkillCard = () => {
  return (
    <Card>
      <CardHeader title="Skills" />
      <CardContent sx={{ paddingTop: "8px" }}>
        <Stack direction="column" spacing={1.5}>
          {skillsInfo.map((skillList) => (
            <Stack direction="column" key={skillList.title}>
              <Typography variant="body1">{skillList.title}:</Typography>
              <Grid container spacing={1}>
                {skillList.skills.map((skill) => (
                  <Grid xs="auto" item key={skill}>
                    <Chip
                      label={skill}
                      variant="filled"
                      color="primary"
                      size="small"
                      component={motion.div}
                      drag
                      dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
                      dragSnapToOrigin={true}
                      dragMomentum={true}
                      dragElastic={0.15}
                      elevation={24}
                      sx={{ cursor: "pointer" }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Stack>
          ))}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default SkillCard;
