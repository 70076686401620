import { useEffect } from "react";
import { seo } from "../../Helpers/Helpers";
import Container from "@mui/material/Container";

const PageContainer = ({ pageTitle, pageDescription, children }) => {
  useEffect(() => {
    seo({
      title: pageTitle,
      metaDescription: pageDescription,
    });
  }, [pageTitle, pageDescription]);
  return (
    <Container component="main" disableGutters>
      {children}
    </Container>
  );
};

export default PageContainer;
