import { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";

// Components
import { currentDate } from "../../Helpers/Helpers";
import Card from "../common/Card";
import CardHeader from "../common/CardHeader";

// icons
import MoreVertIcon from "@mui/icons-material/MoreVert";

//Pictures
import userImg from "../../assets/images/Profile_Picture.webp";

const PostCard = () => {
  const [date, setDate] = useState("");

  useEffect(() => {
    setDate(currentDate());
  }, []);

  return (
    <Card>
      <CardHeader
        avatar={<Avatar src={userImg} alt="Avatar" />}
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title="Max Wanless"
        subheader={date}
      />
      <CardContent>
        <Stack direction="column" spacing={2}>
          <Typography variant="body1">
            Thanks for visiting my site! I'm Max Wanless, I'm a web developer
            based just outside of Toronto. My goal is to build accessible
            design-driven digital infrastructure that can enrich our society’s
            social, mental, and physical well-being. I was drawn to make the
            switch from electrical controls engineering to web development after
            working on a SCADA project, where I discovered a passion for
            creating intuitive and accessible user interfaces. I am an
            analytical problem-solver at heart, and, with my background in
            controls engineering, I have over 5 years of experience overcoming
            complex technical challenges. I’m always open to new opportunities
            and would love to connect!
          </Typography>
          <CardMedia
            component="img"
            height="100%"
            width="100%"
            image={require("../../assets/images/mini.webp")}
            alt="Max and his mini"
            sx={{ borderRadius: "0.75rem" }}
          />
        </Stack>
      </CardContent>
    </Card>
  );
};

export default PostCard;
