import { Link, useLocation } from "react-router-dom";
import { useTheme } from "@emotion/react";
import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// Data
import { pages } from "../../data/data";

//Components
import SettingsMenu from "../SettingsMenu/SettingsMenu";

//Pictures
import userImg from "../../assets/images/Profile_Picture.webp";

const Header = () => {
  const colors = useTheme().palette;
  const path = useLocation().pathname;

  return (
    <Card
      component="header"
      sx={{
        transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        overflow: "hidden",
        borderRadius: "0.75rem",
        zIndex: "0",
        height: "280px",
        position: "relative",
      }}
    >
      <CardMedia
        media="picture"
        alt="Cover 2"
        image="https://images.unsplash.com/photo-1605544553464-7f453296720d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2680&q=80"
        title="Cover 2"
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          height: "100%",
          width: "100%",
        }}
      />
      <Box
        sx={{
          "&::before": {
            backdropFilter: "blur(6px)",
            backgroundColor: colors.primary.dark,
            opacity: 0.8,
            top: "0px",
            zIndex: "9",
            content: '""',
            width: "100%",
            height: "100%",
            position: "absolute",
          },
        }}
      >
        <Box
          sx={{
            right: { xs: "8px", md: "16px" },
            top: { xs: "8px", md: "16px" },
            zIndex: "99",
            position: "absolute",
          }}
        >
          <SettingsMenu />
        </Box>
        <Box
          sx={{
            left: { xs: "0px", md: "24px" },
            right: { xs: "0px", md: "auto" },
            bottom: { xs: "unset", md: "24px" },
            display: { xs: "unset", md: "flex" },
            alignItems: { xs: "unset", md: "center" },
            zIndex: "99",
            position: "absolute",
            marginTop: "40px",
          }}
        >
          <Avatar
            src={userImg}
            alt="Avatar"
            sx={{
              width: { xs: "80px", md: "128px" },
              height: { xs: "80px", md: "128px" },
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexShrink: "0",
              fontSize: "1.25rem",
              lineHeight: "1",
              borderRadius: "50%",
              overflow: "hidden",
              userSelect: "none",
              color: "rgb(255, 255, 255)",
              backgroundColor: "rgb(255, 86, 48)",
              fontWeight: "600",
              marginLeft: "auto",
              marginRight: "auto",
              borderWidth: "2px",
              borderStyle: "solid",
              borderColor: "rgb(255, 255, 255)",
            }}
          />
          <Box
            sx={{
              color: "#ffffff",
              textAlign: { xs: "center", md: "left" },
              marginTop: { xs: "8px", md: "0px" },
              marginLeft: { xs: "unset", md: "24px" },
            }}
          >
            <Typography
              variant="h4"
              sx={{
                margin: "0px",
                fontWeight: "700",
                lineHeight: "1.5",
                fontSize: "1.25rem",
                fontFamily: '"Public Sans", sans-serif',
              }}
            >
              Max Wanless
            </Typography>
            <Typography
              variant="body1"
              sx={{
                margin: "0px",
                lineHeight: "1.5",
                fontSize: "1rem",
                fontFamily: '"Public Sans", sans-serif',
                fontWeight: "400",
                opacity: "0.72",
              }}
            >
              Software Developer
            </Typography>
          </Box>
        </Box>
        {/* tabs */}
        <Tabs
          component="nav"
          value={path}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          sx={{
            width: "100%",
            bottom: "0px",
            zIndex: "9",
            position: "absolute",
            backgroundColor: colors.background.paper,
            ".MuiTabs-flexContainer": {
              paddingRight: { xs: "0", md: "24px" },
              justifyContent: { xs: "flex-start", md: "flex-end" },
            },
          }}
        >
          {pages.map((page) => (
            <Tab
              key={page.label}
              value={`/${page.link}`}
              label={page.label}
              icon={page.icon}
              iconPosition="start"
              to={`/${page.link}`}
              component={Link}
              sx={{
                minHeight: "48px",
              }}
            />
          ))}
        </Tabs>
      </Box>
    </Card>
  );
};

export default Header;
