import MuiCard from "@mui/material/Card";

const Card = (props) => {
  return (
    <MuiCard sx={{ borderRadius: "0.75rem", ...props.sx }}>
      {props.children}
    </MuiCard>
  );
};

export default Card;
