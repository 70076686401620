import { useEffect } from "react";
import { useContext } from "react";
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from "@mui/material/styles";
import { ModeContext } from "./modeContext";
import useMediaQuery from "@mui/material/useMediaQuery";

export const ThemeContext = ({ children }) => {
  const { mode, setMode, primaryColor } = useContext(ModeContext);
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  useEffect(() => {
    setMode(prefersDarkMode ? "dark" : "light");
  }, [prefersDarkMode, setMode]);

  const darkTheme = createTheme({
    typography: {
      fontFamily: "Public Sans, sans-serif",
    },
    palette: {
      type: "dark",
      primary: {
        main: primaryColor,
      },
      mode,
    },
  });

  const lightTheme = createTheme({
    typography: {
      fontFamily: "Public Sans, sans-serif",
    },
    palette: {
      type: "light",
      primary: {
        main: primaryColor,
      },
      mode,
    },
  });
  return (
    <ThemeProvider
      theme={
        mode === "dark"
          ? responsiveFontSizes(darkTheme)
          : responsiveFontSizes(lightTheme)
      }
    >
      {children}
    </ThemeProvider>
  );
};
