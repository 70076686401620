import { Outlet } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";

// Components
import Header from "../common/Header";

const AppLayout = () => {
  return (
    <Container disableGutters maxWidth="lg">
      <Stack direction="column" spacing={2} padding={2}>
        <Header />
        <Outlet />
      </Stack>
    </Container>
  );
};

export default AppLayout;
