import { useContext } from "react";
import { ModeContext } from "../../themes/modeContext";

import Switch from "@mui/material/Switch";

const ColorModeToggle = () => {
  const { mode, setMode } = useContext(ModeContext);

  const toggleColorMode = () => {
    setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
  };
  return (
    <Switch
      checked={mode === "light" ? false : true}
      onChange={toggleColorMode}
    />
  );
};

export default ColorModeToggle;
