import React from "react";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

// Components
import Card from "../common/Card";
import CardHeader from "../common/CardHeader";

// Icons
import MoreVertIcon from "@mui/icons-material/MoreVert";

const ExperienceCard = ({ job }) => {
  return (
    <Card>
      <CardHeader
        title={job.position}
        subheader={`${job.company} - ${job.year}`}
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
      />
      <CardContent>
        <Typography variant="body">{job.description}</Typography>
      </CardContent>
    </Card>
  );
};

export default ExperienceCard;
