import { createContext, useState } from "react";

export const ModeContext = createContext();

export const ModeProvider = ({ children }) => {
  const [mode, setMode] = useState("light");
  const [primaryColor, setPrimaryColor] = useState("#ffe66b");

  return (
    <ModeContext.Provider
      value={{ mode, setMode, primaryColor, setPrimaryColor }}
    >
      {children}
    </ModeContext.Provider>
  );
};
