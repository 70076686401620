import React, { useRef, useState } from "react";
import { Field, Form, Formik } from "formik";
import { object, string } from "yup";
import emailjs from "@emailjs/browser";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import LoadingButton from "@mui/lab/LoadingButton";

// Components
import PageContainer from "../components/common/PageContainer";
import Card from "../components/common/Card";
import SocialCard from "../components/About/SocialCard";

const initialValues = {
  name: "",
  email: "",
  message: "",
};

const Contact = () => {
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarSeverity, setsnackbarSeverity] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const emailForm = useRef();

  const validationSchema = object({
    name: string().required("Please enter name").min(2, "Name too short"),
    email: string()
      .required("Please enter email")
      .email("Please enter valid email"),
    message: string()
      .required("Please enter message")
      .min(2, "Message too short"),
  });

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
    setsnackbarSeverity("");
  };

  const handleSendEmail = (values, formikHelpers) => {
    setLoading(true);

    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        {
          reply_to: values.email,
          to_name: "Max",
          from_name: values.name,
          message: values.message,
        },
        process.env.REACT_APP_EMAILJS_KEY
      )
      .then(
        (result) => {
          setsnackbarSeverity("success");
          setOpenSnackbar(true);
          setLoading(false);

          formikHelpers.resetForm();
          setFormSubmitted(true);
        },
        (error) => {
          setsnackbarSeverity("error");
          setLoading(false);
          setOpenSnackbar(true);
        }
      );
  };

  return (
    <PageContainer
      pageTitle={"Max Wanless | Contact"}
      pageDescription={"Contact page"}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SocialCard />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={8}>
          <Card>
            <CardHeader title="Contact Me" sx={{ pb: "0" }} />
            <CardContent>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, formikHelpers) =>
                  handleSendEmail(values, formikHelpers)
                }
              >
                {({ errors, isValid, touched, dirty }) => (
                  <Stack ref={emailForm} component={Form} spacing={2}>
                    <Field
                      name="name"
                      type="name"
                      as={TextField}
                      label="Full Name"
                      disabled={formSubmitted}
                      error={Boolean(errors.name) && Boolean(touched.name)}
                      helperText={Boolean(touched.name) && errors.name}
                    />
                    <Field
                      name="email"
                      type="email"
                      as={TextField}
                      label="Email"
                      disabled={formSubmitted}
                      error={Boolean(errors.email) && Boolean(touched.email)}
                      helperText={Boolean(touched.email) && errors.email}
                    />
                    <Field
                      name="message"
                      type="message"
                      as={TextField}
                      label="Message"
                      disabled={formSubmitted}
                      multiline
                      rows={4}
                      error={
                        Boolean(errors.message) && Boolean(touched.message)
                      }
                      helperText={Boolean(touched.message) && errors.message}
                    />
                    <LoadingButton
                      variant="contained"
                      type="submit"
                      loading={loading}
                      disabled={!isValid || !dirty}
                    >
                      {formSubmitted ? "Message Sent" : "Send Message"}
                    </LoadingButton>
                  </Stack>
                )}
              </Formik>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={4000}
        open={openSnackbar && snackbarSeverity === "success"}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          Message Sent!
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={4000}
        open={openSnackbar && snackbarSeverity === "error"}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          sx={{ width: "100%" }}
        >
          Oops! Something went wrong.
        </Alert>
      </Snackbar>
    </PageContainer>
  );
};

export default Contact;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
