import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

// Components
import ExperienceCard from "../components/Experience/ExperienceCard";
import ExperienceSumary from "../components/Experience/ExperienceSumary";
import PageContainer from "../components/common/PageContainer";

// Data
import { resumeInfo } from "../data/data";
import Resume from "../assets/Resume/Resume-Max-Wanless.pdf";

// Icon
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

const Experience = () => {
  return (
    <PageContainer
      pageTitle={"Max Wanless | Experience"}
      pageDescription={"Experience page"}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} display="flex" justifyContent="flex-end">
          <Button
            startIcon={<ArrowDownwardIcon />}
            href={Resume}
            target="_blank"
            variant="contained"
          >
            Resume
          </Button>
        </Grid>
        <Grid item xs={12} md={4}>
          <ExperienceSumary />
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container spacing={2}>
            {resumeInfo.map((job) => (
              <Grid key={job.company} item xs={12}>
                <ExperienceCard job={job} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default Experience;
