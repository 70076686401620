import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import CardHeader from "@mui/material/CardHeader";
import CardActionArea from "@mui/material/CardActionArea";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";

// Components
import Card from "../common/Card";

const ProjectCard = ({ project, handleOpenModal }) => {
  return (
    <Card sx={{ height: "100%" }}>
      <CardActionArea
        sx={{ height: "100%" }}
        onClick={() => handleOpenModal(project)}
      >
        <CardContent sx={{ paddingBottom: "0" }}>
          <CardMedia
            component="img"
            height="fit-content"
            image={project.image}
            alt={project.title}
            sx={{ mb: "1rem" }}
          />
        </CardContent>
        <CardContent sx={{ paddingBottom: "0", paddingTop: "0" }}>
          <Stack direction="row" spacing={1}>
            {project.tech.map((item) => (
              <Chip key={item} label={item} sx={{ cursor: "pointer" }} />
            ))}
          </Stack>
        </CardContent>

        <CardHeader title={project.title} subheader={project.description} />
      </CardActionArea>
    </Card>
  );
};

export default ProjectCard;
