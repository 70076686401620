import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

// Components
import Card from "../common/Card";
import CardHeader from "../common/CardHeader";

const ExperienceSumary = () => {
  return (
    <Card>
      <CardHeader title="Summary" />
      <CardContent>
        <Typography variant="body">
          I’m a recent graduate from the Web Development program at BrainStation
          with a background in Industrial Automation. I first became interested
          in web development when working on a SCADA project as a Controls
          Engineer. My background in Engineering has helped me develop my skills
          for solving complex problems as well as learning new technologies in a
          quick and efficient manner.
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ExperienceSumary;
