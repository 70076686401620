import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Divider from "@mui/material/Divider";
import useMediaQuery from "@mui/material/useMediaQuery";

// Icons
import CloseIcon from "@mui/icons-material/Close";

const ProjectDetailModal = ({ openModal, handleCloseModal, project }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return project ? (
    <Dialog open={openModal} fullScreen={fullScreen} scroll="paper">
      <CardHeader
        title={project.title}
        subheader={project.subTitle}
        action={
          <IconButton aria-label="close" onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
        }
      />

      <Divider />
      <DialogContent>
        <Stack direction="column" spacing={2}>
          <CardMedia
            component="img"
            image={project.image}
            alt={project.altImage}
          />

          <Stack direction="row" spacing={1}>
            {project?.tech.map((item) => (
              <Chip key={item} label={item} />
            ))}
          </Stack>

          <Stack direction="row" spacing={3}>
            <Stack direction="column" spacing={2}>
              <WorkInfoDiv>
                <TitleText>My Role</TitleText>
                <InfoText>{project.role}</InfoText>
              </WorkInfoDiv>
              <WorkInfoDiv>
                <TitleText>Teammate</TitleText>
                <InfoText>{project.teammate}</InfoText>
              </WorkInfoDiv>
              <WorkInfoDiv>
                <TitleText>Timeline</TitleText>
                <InfoText>{project.timeline}</InfoText>
              </WorkInfoDiv>
            </Stack>
            <Stack direction="column" spacing={2}>
              <WorkInfoDiv>
                <TitleText>Description</TitleText>
                <InfoText>{project.description}</InfoText>
              </WorkInfoDiv>
              <WorkInfoDiv>
                <TitleText>Context</TitleText>
                <InfoText>{project.context}</InfoText>
              </WorkInfoDiv>
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        {project.github ? <Button href={project.github}>Github</Button> : null}
        {project.demo ? <Button href={project.demo}>Demo</Button> : null}
      </DialogActions>
    </Dialog>
  ) : null;
};

export default ProjectDetailModal;

const TitleText = styled(Typography)({
  marginBottom: "12px",
  color: "#747474",
  fontSize: "12px",
  lineHeight: "1",
  fontWeight: "500",
  textTransform: "uppercase",
});

const InfoText = styled(Typography)({
  marginBottom: 0,
  lineHeight: "22px",
  fontSize: "1rem",
  fontWeight: 400,
});

const WorkInfoDiv = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
});
