import Grid from "@mui/material/Grid";

//Component
import PageContainer from "../components/common/PageContainer";
import AboutCard from "../components/About/AboutCard";
import SkillCard from "../components/About/SkillCard";
import PostCard from "../components/About/PostCard";
import SocialCard from "../components/About/SocialCard";

const About = () => {
  return (
    <PageContainer
      pageTitle={"Max Wanless | About"}
      pageDescription={"About page"}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AboutCard />
            </Grid>
            <Grid item xs={12}>
              <SocialCard />
            </Grid>
            <Grid item xs={12}>
              <SkillCard />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={8}>
          <PostCard />
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default About;
