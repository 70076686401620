import { useState } from "react";
import Grid from "@mui/material/Grid";

// Components
import ProjectCard from "../components/ProjectCard/ProjectCard";
import PageContainer from "../components/common/PageContainer";
import ProjectDetailModal from "../components/ProjectCard/ProjectDetailModal";

// Data
import { projectList } from "../data/data.js";

const Projects = () => {
  const [openModal, setOpenModal] = useState(false);
  const [currentProject, setCurrentProject] = useState(null);

  const handleOpenModal = (project) => {
    setOpenModal(true);
    setCurrentProject(project);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setCurrentProject(null);
  };

  return (
    <PageContainer
      pageTitle={"Max Wanless | Projects"}
      pageDescription={"Projects page"}
    >
      <ProjectDetailModal
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        project={currentProject}
      />
      <Grid container spacing={2}>
        {projectList.map((project) => (
          <Grid key={project.title} item xs={12} md={6} lg={4}>
            <ProjectCard project={project} handleOpenModal={handleOpenModal} />
          </Grid>
        ))}
      </Grid>
    </PageContainer>
  );
};

export default Projects;
