import { useContext } from "react";
import { ModeContext } from "../../themes/modeContext";
import React from "react";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

// Components
import ColorModeToggle from "../common/ColorModeToggle";

// Icons
import SettingsIcon from "@mui/icons-material/Settings";
import CircleIcon from "@mui/icons-material/Circle";

const SettingsMenu = () => {
  const { setPrimaryColor } = useContext(ModeContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleColorChange = (color) => {
    setPrimaryColor(color);
  };
  const colors = [
    { name: "Red", color: "#ff3030" },
    { name: "Purple", color: "#7635dc" },
    { name: "Green", color: "#00ab55" },
    { name: "Blue", color: "#2065d1" },
    { name: "Orange", color: "#ffe66b" },
  ];
  // #fda92d

  return (
    <div>
      <Tooltip title="Settings">
        <IconButton
          id="settings-menu-button"
          aria-controls={open ? "settings-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <SettingsIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            borderRadius: "0.5rem",
            mt: 1.5,
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
      >
        <MenuItem>
          <Box
            display="flex"
            width="100%"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="body1">Colour Mode:</Typography>
            <ColorModeToggle />
          </Box>
        </MenuItem>
        <MenuItem>
          <Box display="flex" flexDirection="column" width="100%">
            <Typography variant="body1">Accent Colour</Typography>
            <Box display="flex" flexWrap="wrap" width="100%">
              {colors.map((item) => (
                <Tooltip key={item.name} title={item.name}>
                  <IconButton onClick={() => handleColorChange(item.color)}>
                    <CircleIcon sx={{ color: item.color }} />
                  </IconButton>
                </Tooltip>
              ))}
            </Box>
          </Box>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default SettingsMenu;
